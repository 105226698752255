export const Footer = (props) => {
  
  return (
    <footer id='footer'>
      <div className='container'>
      <div className='row'>
        <div className='col-md-4 left studio'>
          <a target="_blank" href="https://studio365.io">
            <img className="logo" src="/img/logo.svg" alt="studio365" />
          </a>  
          <p className="footer-text">Nós reimaginamos o futuro com Microsoft 365</p>
          <a target="_blank" href="https://www.linkedin.com/company/top365/">
            <img className="linkedin" src="/img/linkedin.png" alt="linkedin" />
          </a>
        </div>
        <div className='col-md-2 right links'>
          <p className="footer-text">Codigo de Conduta</p>
          <p className="footer-text">Política de Privacidade</p>
        </div>
        <div className="clear text-center copyright">
          <p>© {new Date().getFullYear()}, Studio 365 | Todos os direitos reservados</p>
        </div>
      </div>
      </div>
    </footer>
  )
}
