export const Header = (props) => {
  return (
    <header id='header'>
      <div className='container'>
        <h3>Inteligência Artificial Generativa treinada com Pareceres e Notas Técnicas de Jurisprudência</h3>
        <h1>Inteligência Artificial para o Direito Jurisprudencial</h1>
        <a href='https://chat.whatsapp.com/HnBYUBop6AwCWAQ8lvRE0g' target="_blank" className='btn btn-custom btn-lg page-scroll'>
          Saiba mais
        </a>

        <div className="features">
          <div className="box">
            <p>+100.00 documentos e 560.000 páginas</p>

            <p>O conhecimento utilizado para treinamento da Inteligência Artificial abordou +100.000 documentos de carácter jurisprudencial.</p>
          </div>
          <div className="box">
            <p>Aprendizado e Inteligência Artificial</p>

            <p>A modelagem de aprendizado para a inteligência artificial envolveu múltiplas etapas, técnicas e tecnologias avançadas envolvendo Serviços Cognitivos, RAG (
              Retrieval Augmentation Generation), LLM (Large Language Model) tipo ChatGPT, dentre outras técnicas, resultando em uma interação conversacional baseada em IA Generativa.</p>
          </div>
          <div className="box">
            <p>Responsabilidade e Privacidade</p>

            <p>Nossa abordagem no uso da Inteligência Artificial é fundamentada em ética e pela explicabilidade das interações, buscando garantir os princípios de inclusão, justiça, transparência, responsabilidade, confiabilidade e segurança, privacidade e proteção.</p>
          </div>
        </div>
      </div>
    </header>
  )
}
